import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import { renderRichText } from "@contentful/rich-text-react-renderer";
import {LayoutPages} from "../components";
import "../scss/index.scss";
import {JSON_Parse} from "../utils/FormatUtils";



const PersonalDataPage = ({languageSelect}) => {

  return <div></div>;
    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulPersonalData {
                    subtitle_ru
                    subtitle_en
                    subtitle_cn
                    description_ru{
                        raw
                    }
                    description_en{
                        raw
                    }
                    description_cn{
                        raw
                    }
                }
              }
            `}
            render={data => {
                const breadcrumbs = [
                    {
                        pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : languageSelect === 'en' ? 'Home' : '首頁'}`, active: false
                    },
                    {
                        pathname: "", crumbLabel: `${data.contentfulPersonalData["subtitle_" + languageSelect]}`, active: true
                    }
                ];

                return (
                    <LayoutPages titlePage={data.contentfulPersonalData["subtitle_" + languageSelect]}
                                 breadcrumbs={breadcrumbs}
                    >
                        <section className="section">
                            <div className="container">
                                <div className="about-text">
                                    {JSON_Parse(data.contentfulPersonalData['description_' + languageSelect].raw)}
                                </div>
                            </div>
                        </section>
                    </LayoutPages>
                );
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(PersonalDataPage);
